import React, { useState } from "react";
import MasterLayout from "../components/MasterLayout";
import Article from "../components/Article";
import Seo from "../components/Seo";
import { graphql } from "gatsby";
import "./reviews.css";

const Reviews = props => {
  const { data } = props;
  const allPosts = data.allMdx.edges;
  const emptyQuery = "";

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery
  });

  const handleInputChange = evt => {
    const query = (evt.target.value || "").toLowerCase();
    const posts = data.allMdx.edges || []
    
    const filteredData = posts.filter(post => {      
      const { title, blurb, tags } = post.node.frontmatter;
      return (
        title.toLowerCase().includes(query) ||
        blurb.toLowerCase().includes(query) ||
        (tags && tags.join("").toLowerCase().includes(query))
      )
    })

    setState({
      query,
      filteredData
    });
  };

  const { filteredData, query } = state;
  const hasSearchResults = filteredData && query !== emptyQuery;
  const posts = hasSearchResults ? filteredData : allPosts;

  return (
    <MasterLayout>
      <Seo 
        title="Reviews"
        description="Gaming Reviews"
        pathname="Reviews"
        article={false}
      />
      <div className="main-content">
        <h2>Gaming Reviews</h2>
        <h4>{posts.count}</h4>
        <div className="searchBox">
          <input
            className="searchInput"
            type="text"
            placeholder="Type to filter posts..."
            onChange={handleInputChange}
          />
        </div>

        {posts.map(({ node }) => (
          <div key={node.id} className='review'>
            <Article 
              slug={node.fields.slug} 
              title={node.frontmatter.title} 
              date={node.frontmatter.date}
              blurb={node.frontmatter.blurb}
              excerpt={node.excerpt}/>
          </div>
        ))}
      </div>
    </MasterLayout>
  )
}

export default Reviews;

export const query = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: [DESC] }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            blurb
            tags
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }    
  }
`